const CategoryReducer = (state, action) => {
  switch (action.type) {
    case 'GET_CATEGORIES': {
      return {
        ...state,
        categories: action.payload,
        loading: false
      };
    }
    case 'SET_LOADING_TRUE': {
      return {
        ...state,
        loading: true
      };
    }

    case 'SET_LOADING_FALSE': {
      return {
        ...state,
        loading: false
      };
    }

    default: {
      return state;
    }
  }
};

export default CategoryReducer;
