import React from 'react';
import '../assets/style/loading.css';

const FullPageLoading = () => {
  return (
    <div className="fixed bg-white flex items-center justify-center z-[999] h-[100vh] w-[100%] overflow-visible m-auto inset-0">
      <span className="loader"></span>
    </div>
  );
};

export default FullPageLoading;
