import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

const Banner = React.lazy(() => import("../shared/Banner"));
const Navbar = React.lazy(() => import("../shared/Navbar"));
const Home = React.lazy(() => import("../pages/home"));
const Booking = React.lazy(() => import("../pages/booking"));
const Contact = React.lazy(() => import("../pages/contact"));
const Footer = React.lazy(() => import("../shared/Footer"));

const RouteConfig = () => {
  return (
    <div>
      <Router>
        <Banner />
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/booking/:id" element={<Booking />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
};

export default RouteConfig;
