import React, { createContext, useReducer } from 'react';
import CategoryReducer from './Reducer';

//Initial State
let initialState = {
  categories: [],
  loading: false
};

export const CategoryContext = createContext(initialState);

const CategoryProvider = ({ children }) => {
  let [state, dispatch] = useReducer(CategoryReducer, initialState);

  const fetchCategories = async () => {
    try {
      setLoadingTrue();
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/categories`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      dispatch({
        type: 'GET_CATEGORIES',
        payload: data
      });
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingFalse();
    }
  };

  const setLoadingTrue = () => {
    dispatch({
      type: 'SET_LOADING_TRUE'
    });
  };

  const setLoadingFalse = () => {
    dispatch({
      type: 'SET_LOADING_FALSE'
    });
  };

  //Context Values
  const contextValues = {
    fetchCategories,
    setLoadingTrue,
    setLoadingFalse,
    ...state
  };

  return <CategoryContext.Provider value={contextValues}>{children}</CategoryContext.Provider>;
};
export default CategoryProvider;
