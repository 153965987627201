import React, { Suspense } from 'react';
import './assets/style/index.css';
import FullPageLoading from './shared/FullPageLoading';
import RouteConfig from './router/RouteConfig';
import CategoryProvider from './context/Context';

const App = () => {
  return (
    <Suspense fallback={<FullPageLoading />}>
      <CategoryProvider>
        <RouteConfig />
      </CategoryProvider>
    </Suspense>
  );
};

export default App;
